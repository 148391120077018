<template>
  <div class="relative">
    <div class="vx-navbar-wrapper navbar-full p-0">
      <vs-navbar
        class="navbar-custom navbar-skelton"
        :class="navbarClasses"
        :style="navbarStyle"
        :color="navbarColor"
      >
        <feather-icon
          class="sm:inline-flex xl:hidden cursor-pointer mr-1"
          icon="MenuIcon"
          @click.stop="showSidebar"
        ></feather-icon>

        <div
          v-if="windowWidth >= 992"
          class="absolute left-0 top-0 flex cursor-pointer mx-auto mt-4 sm:ml-6 md:ml-6 lg:ml-6 xl:ml-6 ml-20"
        >
          <transition :name="routerTransition">
            <div
              v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
              class="router-header flex flex-wrap items-center mb-6"
            >
              <div
                class="content-area__heading"
                :class="{
                  'pr-4 border-0 md:border-r border-solid border-grey-light':
                    $route.meta.breadcrumb
                }"
              >
                <h2 class="mb-1">{{ $t(routeTitle) }}</h2>
              </div>

              <vx-breadcrumb
                class="ml-4 md:block hidden"
                v-if="$route.meta.breadcrumb"
                :route="$route"
                :isRTL="$vs.rtl"
              />
            </div>
          </transition>
        </div>

        <router-link
          tag="div"
          to="/"
          class="vx-logo cursor-pointer mx-auto flex items-center"
        ></router-link>

        <div class="text-right leading-tight hidden sm:block ml-3 mr-3">
          <p class="font-semibold" style="color:#3268a8">{{ user_Email }}</p>
          <small style="color:#3268a8">{{ $t("Welcome") }}</small>
        </div>
        
        <!-- Notification Icon -->
        <div class="relative">
          <feather-icon
            icon="BellIcon"
            class="cursor-pointer"
            @click="toggleNotifications"
          ></feather-icon>
          <div
            v-if="showNotificationDropdown"
            class="absolute right-0 mt-2 w-80 bg-white shadow-lg rounded-lg"
          >
            <div class="px-4 py-2 text-gray-700">
              <h3 class="font-semibold">Notifications</h3>
              <div
                v-if="notifications.length === 0"
                class="py-2 text-center"
              >
                No notifications
              </div>
              <div
                v-else
                class="overflow-y-auto"
                style="max-height: 200px;" 
              >
                <div
                  v-for="notification in notifications"
                  :key="notification.id"
                  class="py-2 border-b"
                >
                  {{ notification.message }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <i18n style="padding-right: 20px; padding-left: 20px;" />
        <profile-drop-down />
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import I18n from "./components/I18n.vue";
import ProfileDropDown from "./components/ProfileDropDown.vue";
import themeConfig from "@/../themeConfig.js";

export default {
  name: "the-navbar-horizontal",
  data() {
    return {
      routeTitle: this.$route.meta.pageTitle,
      routerTransition: themeConfig.routerTransition || "none",
      showNotificationDropdown: false,
      notifications: [
        { id: 1, message: "New message from John Doe" },
        { id: 2, message: "Your report is ready to download" },
        { id: 3, message: "System update available" },
        { id: 4, message: "Appointment scheduled with Jane Doe" },
        { id: 5, message: "Meeting at 3 PM tomorrow" },
        { id: 6, message: "Password reset request" },
        { id: 7, message: "Security alert: new login from device" },
      ],
    };
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
    },
  },
  components: {
    I18n,
    ProfileDropDown,
  },
  methods: {
    showSidebar() {
      this.$store.commit("TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE", true);
    },
    toggleNotifications() {

        this.$router.push({ name: 'Complaint' });
      //this.showNotificationDropdown = !this.showNotificationDropdown;
      // if(!this.showNotificationDropdown){
      //   this.notifications=[];
      // }

    },
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    user_Email() {
      return this.activeUserInfo.Email;
    },
    navbarColor() {
      return "#fff";
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      return this.navbarType === "static"
        ? { transition: "all .25s ease-in-out" }
        : {};
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg shadow-none";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },
  },
};
</script>
