/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  Strucutre:
          url     => router path
          name    => name to display in sidebar
          slug    => router path name
          icon    => Feather Icon component/icon name
          tag     => text to display on badge
          tagColor  => class to apply on badge element
          i18n    => Internationalization
          submenu   => submenu of current item (current item will become dropdown )
                NOTE: Submenu don't have any icon(you can add icon if u want to display)
          isDisabled  => disable sidebar item/group
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

//const screenRolesPermissions = JSON.parse(localStorage.getItem('ScreenRolesPermissions')) || [];
const checkPermission = (pageName) => {
  const screenRolesPermissions = JSON.parse(localStorage.getItem('ScreenRolesPermissions')) || [];

  // Ensure Page and Page.Name are not null or undefined
  return screenRolesPermissions.some(permission =>
    permission.Page && permission.Page.Name === pageName
  );
};
// const checkPermissionCategory = (Cat) => {
//   debugger;
//   var x=screenRolesPermissions.some(permission => permission.Page.PageCategory.Name === Cat);
//   return x;
// };
export default [
  {
    url: "/",
    name: "Home",
    slug: "home",
    icon: "HomeIcon",
   // show: JSON.parse(localStorage.getItem("userInfo")).UserType == "Admin",
   // show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin",
   show:true,
   i18n: "Home"
  },
  {
    url: "PushAllNotification",
    name: "PushAllNotification",
    slug: "PushAllNotification",
    icon: "BellIcon",
   // show: JSON.parse(localStorage.getItem("userInfo")).UserType == "Admin",
   // show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin",
    show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("PushAllNotification"),
   i18n: "PushAllNotification"
  },
  // {
  //   url:"/Notification/Complaint",
  //   name: "Complaint",
  //   slug: "Complaint",
  //   show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin",
  //   icon: "AlertTriangleIcon",
  //   i18n: "Complaints",
  // },
  {
    url:"/BannerAds",
    name: "BannerAds",
    slug: "BannerAds",
    show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin",
    icon: "GridIcon",
    i18n: "BannerAds",
  },

  {
  url: "/CustomerAds",
  name: "CustomerAds",
  slug: "CustomerAds",
  icon: "TagIcon",
  // Determine visibility based on both UserType and permissions
  show: true,
  i18n: "CustomerAds",
  submenu: [
    {
      url: "/CustomerAds/ShowAds",
      name: "ShowAds",
      slug: "ShowAds",
      // Determine submenu visibility based on permissions
      show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("ShowAds"),
      i18n: "ShowAds"
    },

    //     {
    //   url: "/CustomerAds/AdsManagement",
    //   name: "AdsManagement",
    //   slug: "AdsManagement",
    //   // Determine submenu visibility based on permissions
    //   show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("AdsManagement"),
    //   i18n: "AdsManagement"
    // },
  ]
  },
  // {
  //   url: "/ServiceRequests",
  //   name: "ServiceRequests",
  //   slug: "ServiceRequests",
  //   icon: "FileTextIcon",
  //   show: true,
  //   i18n: "ServiceRequests",
  //   submenu: [
  //     {
  //      url:"/ServiceRequests/OffersAccepted",
  //       name: "OffersAccepted",
  //       slug: "OffersAccepted",
  //       show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("OffersAccepted"),

  //       i18n: "OffersAccepted"
  //     },
  //     {
  //       url:"/ServiceRequests/OffersNotAccepted",
  //        name: "OffersNotAccepted",
  //        slug: "OffersNotAccepted",
  //        show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("OffersNotAccepted"),

  //        i18n: "OffersNotAccepted"
  //      },
  //     // {
  //     //   url:"/ServiceRequests/ServiceRequests",
  //     //    name: "ServiceRequests",
  //     //    slug: "ServiceRequests",
  //     //    show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("ServiceRequests"),

  //     //    i18n: "_ServiceRequests"
  //     //  },



  //     ]
  // },
  // {
  //   url: "/PackagesAndSubscriptions",
  //   name: "PackagesAndSubscriptions",
  //   slug: "PackagesAndSubscriptions",
  //   icon: "FileTextIcon",
  //   show: true,
  //   i18n: "PackagesAndSubscriptions",
  //   submenu: [
  //     {
  //      url:"/PackagesAndSubscriptions/BrokerPackage",
  //       name: "BrokerPackage",
  //       slug: "BrokerPackage",
  //       show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("BrokerPackage"),

  //       i18n: "CustomerPackages"
  //     },
  // //     {
  // //       url:"/PackagesAndSubscriptions/ServiceProviderSubscription",
  // //        name: "ServiceProviderSubscription",
  // //        slug: "ServiceProviderSubscription",
  // //        show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("ServiceProviderSubscription"),

  // //        i18n: "ServiceProviderSubscription"
  // //      },



  //   ]
  //  },
  {
    url: "/Setting",
    name: "Setting",
    slug: "Setting",
    icon: "SettingsIcon",
    show: true,
    i18n: "Setting",
    submenu: [

      
      //  {
      //   url:"/Settings/Privacy",
      //    name: "Privacy",
      //    slug: "Privacy",
      //    show: true,
      //    i18n: "Privacy",

      //  },
     
      // {
      //   url:"/Settings/UnitType",
      //    name: "UnitType",
      //    slug: "UnitType",
      //    show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("UnitType"),

      //    i18n: "UnitType",

      //  },
      //  {
      //   url:"/Settings/Nationality",
      //    name: "Nationality",
      //    slug: "Nationality",
      //    show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("Nationality"),

      //    i18n: "Nationality",

      //  },
       
      //  {
      //   url:"/Settings/RealEstateServicesManagement/ServicesManagement",
      //    name: "ServicesManagement",
      //    slug: "ServicesManagement",
      //    show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("ServicesManagement"),
      //    i18n: "ServicesManagement"
      //  },
      //  {
      //    url:"/Settings/RealEstateServicesManagement/ServiceTypeManagement",
      //     name: "ServiceTypeManagement",
      //     slug: "ServiceTypeManagement",
      //     show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("ServiceTypeManagement"),

      //     i18n: "ServiceTypeManagement"
      //   },
        {
          url:"/Settings/Subscriptions/Packages",
           name: "Packages",
           slug: "Packages",
           show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("Packages"),

           i18n: "CustomerPackages"
         },
         {
           url:"/Settings/SearchKeyWords/SearchKeyWords",
            name: "SearchKeyWords",
            slug: "SearchKeyWords",
            show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("SearchKeyWords"),

            i18n: "SearchKeyWords"
          },
          {
            url:"/Settings/AdsCatgeory",
             name: "AdsCatgeory",
             slug: "AdsCatgeory",
             show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("AdsCatgeory"),

             i18n: "AdsCatgeory"
           },

           {
            url:"/Settings/properties",
             name: "properties",
             slug: "properties",
             show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("properties"),

             i18n: "properties"
           },
           {
            url:"/Settings/PropertySpecs",
             name: "PropertySpecs",
             slug: "PropertySpecs",
             show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("PropertySpecs"),

             i18n: "PropertySpecs"
           },
           {
            url:"/Settings/Country",
             name: "Country",
             slug: "Country",
             show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("Country"),
        
             i18n: "Country",
        
           },
           {
            url:"/Settings/Region",
             name: "Region",
             slug: "Region",
             show: true,
             i18n: "Region",
        
           },
           {
            url:"/Settings/City",
             name: "City",
             slug: "City",
             show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("City"),
        
             i18n: "City",
        
           },
          //  {
          //   url:"/Settings/MobileSliders/MobileSliders",
          //    name: "MobileSliders",
          //    slug: "MobileSliders",
          //    show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("MobileSliders"),

          //    i18n: "MobileSliders"
          //  },
    ]
  },
 
  

  // {
  //   url: "/BannerAds",
  //   name: "BannerAds",
  //   slug: "BannerAds",
  //   icon: "FileTextIcon",
  //   show: true,
  //   i18n: "BannerAds",
  //   submenu: [
  //     {
  //      url:"/BannerAds/BannerAds",
  //       name: "BannerAds",
  //       slug: "BannerAds",
  //       show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("BannerAds"),

  //       i18n: "BannerAds"
  //     },



  //     ]
  // },
  // {
  //   url: "/Reports",
  //   name: "Reports",
  //   slug: "Reports",
  //   icon: "PrinterIcon",
  //   show: true,
  //   i18n: "Reports",
  //   submenu: [
  //     {
  //      url:"/Reports/BrokerReport",
  //       name: "BrokerReport",
  //       slug: "BrokerReport",
  //       show: true,
  //       i18n: "BrokerReport"
  //     },
  //     {
  //       url:"/Reports/ServiceProviderReport",
  //        name: "ServiceProviderReport",
  //        slug: "ServiceProviderReport",
  //        show: true,
  //        i18n: "ServiceProviderReport"
  //      },
  //      {
  //       url:"/Reports/CustomerReport",
  //        name: "CustomerReport",
  //        slug: "CustomerReport",
  //        show: true,
  //        i18n: "CustomerReport"
  //      },
  //      {
  //       url:"/Reports/EmployeeReport",
  //        name: "EmployeeReport",
  //        slug: "EmployeeReport",
  //        show: true,
  //        i18n: "EmployeeReport"
  //      },
  //      {
  //       url:"/Reports/CustomerAdsReport",
  //        name: "CustomerAdsReport",
  //        slug: "CustomerAdsReport",
  //        show: true,
  //        i18n: "CustomerAdsReport"
  //      },
  //      {
  //       url:"/Reports/AcceptedOffersReport",
  //        name: "AcceptedOffersReport",
  //        slug: "AcceptedOffersReport",
  //        show: true,
  //        i18n: "AcceptedOffersReport"
  //      },
  //      {
  //       url:"/Reports/NotAcceptedOffersReport",
  //        name: "NotAcceptedOffersReport",
  //        slug: "NotAcceptedOffersReport",
  //        show: true,
  //        i18n: "NotAcceptedOffersReport"
  //      },
  //      {
  //       url:"/Reports/BrokerSubscriptionReport",
  //        name: "BrokerSubscriptionReport",
  //        slug: "BrokerSubscriptionReport",
  //        show: true,
  //        i18n: "BrokerSubscriptionReport"
  //      },
  //      {
  //       url:"/Reports/ServiceProviderSubscriptionReport",
  //        name: "ServiceProviderSubscriptionReport",
  //        slug: "ServiceProviderSubscriptionReport",
  //        show: true,
  //        i18n: "ServiceProviderSubscriptionsReport"
  //      },
  //     ]
  // },
  {
    url: "/TermsAndConditions",
    name: "TermsAndConditions",
    slug: "TermsAndConditions",
    icon: "FileTextIcon",
    show: true,
    i18n: "TermsAndConditions",
    submenu: [
      {
       url:"/TermsAndConditions/TermsAndCondition",
        name: "TermsAndCondition",
        slug: "TermsAndCondition",
        show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("TermsAndConditions"),

        i18n: "TermsAndCondition"
      },



      ]
  },
  {
    url: "/UsersManagement",
    name: "UsersManagement",
    slug: "UsersManagement",
    icon: "UserIcon",
    //show: JSON.parse(localStorage.getItem("userInfo")).UserType == "Admin",
    show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin" ||(JSON.parse(localStorage.getItem("userInfo")).UserType == "Admin"),
    i18n: "UsersManagement",
    submenu: [
      {
       url:"/UsersManagement/Users",
        name: "Users",
        slug: "Users",
        show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("Users"),
        i18n: "Users"
      },
       {
        url:"/UsersManagement/RealEstateContributions",
         name: "RealEstateContributions",
         slug: "RealEstateContributions",
         show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("RealEstateContributions"),
         i18n: "RealEstateContributions"
       },
       {
         url:"/UsersManagement/SpecialRequests",
          name: "SpecialRequests",
          slug: "SpecialRequests",
          show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("SpecialRequests"),
          i18n: "SpecialRequests"
        },
      ]
  },
  {
    url: "/Employee",
    name: "Employee",
    slug: "Employee",
    icon: "PrinterIcon",
    show: true,
    i18n: "UserRole",
    submenu: [
      {
       url:"/Employee/Employee",
        name: "Employee",
        slug: "Employee",
        show: JSON.parse(localStorage.getItem("userInfo")).UserType == "SuperAdmin"||checkPermission("UserRole"),

        i18n: "UserRole"
      },
      

      ]
  },
];


