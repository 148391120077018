<template>
  <div>
    <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo">
      <div class="text-right leading-tight hidden sm:block">
        <p class="font-semibold">{{ firstName }} {{ lastName }}</p>
        <small>{{ $t(accounttype) }}</small>
      </div>

      <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
        <div class="con-img ml-3">
          <img
            v-if="ProfilePic"
            :src="ProfilePic"
            alt="user-img"
            width="40"
            height="40"
            class="rounded-full shadow-md cursor-pointer block"
          />
          <img
              v-else
              key="Default"
               :src="defaultProfilePic"
              alt="Default-user-img"
              width="45"
              height="45"
              class="default-image shadow-md cursor-pointer block rounded-lg"
            />

        </div>

        <vs-dropdown-menu class="vx-navbar-dropdown">
          <ul style="min-width: 12rem;">
            <router-link
              v-show="this.$store.state.AppActiveUser.UserType != 'Admin'"
              :to="{
                name: 'User-Edit',
                params: { ID: this.$store.state.AppActiveUser.ID }
              }"
            >
              <li
                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              >
                <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                <span class="ml-2">{{ $t("Edit-Profile") }}</span>
              </li>
            </router-link>
            <router-link :to="'/ChangePassword'">
              <li
                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              >
                <feather-icon icon="LockIcon" svgClasses="w-4 h-4" />
                <span class="ml-2">{{ $t("ChangePassword") }}</span>
              </li>
            </router-link>
            <router-link :to="'/register'">
              <li
                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              >
                <feather-icon icon="LockIcon" svgClasses="w-4 h-4" />
                <span class="ml-2">{{ $t("Register") }}</span>
              </li>
            </router-link>
            <vs-divider class="m-1" />

            <li
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="logout"
            >
              <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
              <span class="ml-2">{{ $t("Logout") }}</span>
            </li>
          </ul>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>

    <div class="the-navbar__user-meta flex items-center" v-else>
      <div class="text-right leading-tight hidden sm:block">
        <p class="font-semibold">{{ $t("LOGIN") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    firstName() {
      return this.$store.state.AppActiveUser.firstName;
    },
    lastName() {
      return this.$store.state.AppActiveUser.lastName;
    },
    accounttype() {
      return this.$store.state.AppActiveUser.accounttype;
    },
    ProfilePic() {
      return this.$store.state.AppActiveUser.ProfilePic;
    },
    defaultProfilePic() {
      if (window.location.hostname.includes("clixy.net")) {
        return require("@/assets/images/clixy.png");
      } else if (window.location.hostname.includes("ezzeyfind.com")) {
        return require("@/assets/images/Ezzy.jpg");
      } else {
        return require("@/assets/images/clixy.png");
      }
    }
  },
  methods: {
    ChangeParentActiveStudent(item){
     debugger
      if(item.IsActive!=true){

      this.$store.dispatch("auth/ChangeParentActiveStudent",item.ID).then((res)=>{
        debugger
        if(res.data.StatusCode==201){
            this.$vs.notify({
            title: this.$t("Error"),
            text: res.data.message,
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger"
          });
        }
             else{
               location.reload("/");
             }


      });
      }

    },
    logout() {
      this.$store.dispatch("auth/logout");
      // localStorage.removeItem('accessToken')
      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push("/pages/login").catch(() => {});
      //location.reload();
    },

    mounted() {

      console.log(this.$store.state.AppActiveUser);
      if (!this.activeUserInfo) {
        this.$router.push("user/login/login");
      }
    }
  }
};
</script>
<style>
.routerLink {
  text-decoration: none;
}
.conditional-image,
.default-image {
  position: relative; /* Ensure the images are positioned relative to their container */
  z-index: 1; /* Higher z-index ensures the image is in front */
}

.default-image {
  z-index: 0; /* Lower z-index for the default image, to be behind the conditional image */
}
</style>
